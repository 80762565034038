.level-select-view {
  background-color: #f0f0f4;
  width: 100vw;
  height: 100vh;
  margin: 0px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  font-family: sans-serif;
}

.level-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  flex-direction: column;

  width: 200px;
  height: 250px;
  border-radius: 10px;
  margin: 20px;

  border: #8ecb13 solid 5px;
  color: #8ecb13;
}

.level-card-unavailable {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  flex-direction: column;

  width: 200px;
  height: 250px;
  border-radius: 10px;
  margin: 20px;

  border: rgb(237, 59, 106) solid 5px;
  color: rgb(237, 59, 106)
}


.level-img {
  width: 90px;
  height: 90px;
}

.level-btn {
  background-color: #8ecb13;
  color: white;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  border: none;
  font-size: 18px;
  font-weight: bold;
}

.level-btn-rojo {
  background-color: rgb(237, 59, 106);
  color: white;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  border: none;
  font-size: 18px;
  font-weight: bold;
}
