.game-view {
  background-color: white;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Frestaurant.jpg?alt=media&token=80999486-b145-429b-b56d-cb265dfc1328");

  height: 100vh;

  background-size: cover;
}

.texto-pregunta {
  background-color: white;
  margin: 20px;
  height: 70px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: navy;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 8px 0px;
}

.answer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 400px) {
    
  .texto-pregunta{
      margin: 5px;
      height: 110px;
  }
} 
