.cetes-view {
  display: flex;

  justify-content: center;
  align-items: center;
}

.cetes-container {
  background-color: white;
  border-radius: 5px;
  margin: 30px;
  padding: 10px;

  font-family: sans-serif;
  color: navy;

  font-weight: bold;
  max-width: 600px;
  flex-wrap: wrap;
}

.cetes-top {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;
}

.cetes-left {
  display: flex;

  justify-content: center;
  align-items: flex-start;

  flex-direction: column;

  text-align: left;
  flex-wrap: wrap;
  max-width: 400px;
}

.cetes-bottom {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;
}

.cetes-text {
  margin: 5px;
  
}

.cetes-date{
  color: rgb(202, 0, 51);
}

.cetes-title {
  font-size: 20px;
  margin: 5px;
}

.cetes-invertir-btn {
  background-color: rgb(169, 202, 20);

  border: none;
  border-radius: 5px;
  padding: 10px;
  color: navy;
  margin: 10px;

  font-weight: bold;
}

.cetes-img {
  width: 100px;
  margin: 10px;
  margin-right: 20px;
}
