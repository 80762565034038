header {
  background-color: white;
  color: navy;
  font-family: sans-serif;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px;
}

body{
  margin: 0px;
}

.header-logo{
  height: 50px;
  margin: 5px;
}

.right-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 280px;
  justify-content: space-between;
}

.balance-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.balance {
  width: 50px;
  color: rgb(231, 75, 101);
  font-size: 18px;
  font-weight: bolder;
}

.header-btn {
  width: 40px;
  height: 40px;
  margin: 10px;
}

.header-btn:hover {
  cursor: pointer;
}

.logros-btn {
  background-color: rgb(30, 4, 124);
  color: white;
  border-radius: 20px;
  font-weight: bolder;
  width: 80px;
  height: 30px;
  border: none;
}

.logros-btn:hover {
  cursor: pointer;
}

.back-btn {
  width: 40px;
  height: 40px;

  transform: rotate(-90deg);
  margin-left: 10px;
}
