.tienda-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bolder;
}

.tienda-text {
  color: navy;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.tienda-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: navy;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 20px;
  flex-wrap: wrap;
}

.tienda-btn {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  flex-direction: column;
  width: 120px;
  height: 150px;
  border-color: navy;
  border-radius: 5px;
  border: solid 3px;
}

.tienda-btn-img {
  max-width: 80px;
  max-height: 80px;
  margin: 8px;
}

.mirror {
  transform: scaleX(-1);
}

.tienda-btn-name {
  margin: 8px;
  text-align: center;
}

.tienda-btn:hover {
  cursor: pointer;
}
