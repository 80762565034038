.caja-misteriosa {
  width: 170px;
  height: 170px;

  margin: 20px;
}

.img-misteriosa {
  width: 170px;
  height: 170px;
}

.arriesgar-view {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  font-weight: bolder;
  color: navy;
  font-size: 20px;
  font-family: sans-serif;
}

.contenedor-arriesgar {
  display: flex;

  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
