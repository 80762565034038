.catalogo-donaciones {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.donacion-box {
  background-color: white;
  border-radius: 5px;
  height: 180px;
  width: 120px;
  margin: 10px;
  padding: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  color: navy;
}

.donacion-img {
  max-width: 70px;
  max-height: 70px;
  margin: 5px;
}

.donacion-nombre {
  text-align: center;
  margin: 5px;
  font-weight: bolder;
}
.donacion-descripcion {
  text-align: center;
  margin: 5px;
}

.donacion-compra-btn {
  margin: 5px;
  background-color: rgb(176, 218, 27);
  color: navy;
  border: none;
  border-radius: 50px;
  width: 50px;
  height: 25px;
  font-weight: bolder;

}

.donacion-compra-btn:hover {
  cursor: pointer;
}
