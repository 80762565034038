.paywall{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    font-family: sans-serif;
    color: navy;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
}

.pay-btn{
    border-radius: 5px;
    background-color: navy;
    color: white;
    width: 120px;
    height: 40px;
    border: none;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
}