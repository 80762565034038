.answer-card {
  background-color: navy;
  border-radius: 5px;
  color: white;
  width: 400px;
  height: 70px;
  margin: 20px;

  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 8px 0px;
}

.answer-card:hover{
  cursor: pointer;
}

.answer-name{
  margin: 0px;
}

@media screen and (max-width: 400px) {
    
  .answer-card{
      margin: 5px;
      height: 100px;
      
  }
} 