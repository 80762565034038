.form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  /* padding-top: 10%; */
}

.input {
  border-color: navy;
  border-width: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 25px;
  width: 150px;
}

.submit {
  background-color: navy;
  border: none;
  border-radius: 5px;
  height: 32px;
  width: 157px;
  color: white;
  font-weight: bolder;
  margin-bottom: 10px;
}

.welcome-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: navy;
  font-family: sans-serif;

  font-weight: bolder;
}

.welcome-logo{
  margin-top: 30px;
  height: 90px;
  margin-bottom: 5px;
}
